import React from "react";
//import logo from "../../common/images/Ajua-logo-white.svg";
//import logoMobile from "../../common/images/Ajua-logo-color.svg";
import logo from "../../common/images/thryve_logo_white.svg";
import logoMobile from "../../common/images/thryve_logo.svg";
import { NavLink } from 'react-router-dom';
import MobileNav from "./nav";

function Header() {

  const QuickSearch = function(e){
    e.preventDefault();
    document.body.classList.add('search-is-active');
  }

  return (
    <header className="site-header">
      <div className="view-header">
        <div className="view-header__featured">
          <a className="site-logo" href="/">
            <img src={logoMobile} width="150"  alt="MTN EnGauge logo" />
            <h1 className="sr-only">MTN EnGauge</h1>
          </a>
        </div>

        <div className="view-header__left">
          <a href="/" className="js-open-nav" onClick={MobileNav}>
            <i className="ri-menu-line"></i>
          </a>
        </div>
        {/*<div className="view-header__right">*/}
        {/*  <quick-search-trigger className="js-search" onClick={QuickSearch}>*/}
        {/*    <i className="ri-search-2-line"></i>*/}
        {/*  </quick-search-trigger>*/}
        {/*</div>*/}

        <nav className="navigation-drawer">
          <div>
            <ul>
              <li>
                <NavLink to="/">
                  Company
                </NavLink>
              </li>
              {/*<li>*/}
              {/*  <a href="https://tappi.app/blog" target="_blank" rel="noopener noreferrer">*/}
              {/*    <span>Blog</span>*/}
              {/*    <i class="ri-arrow-right-up-line"></i>*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li>
                <NavLink to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
            <hr />

            <ul>
              <li>
                <NavLink to="/privacy-policy">
                  Privacy Policy
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className="view-header-lg" id="view-header-lg">
        <div className="view-header-lg__inner">
          <div className="view-header-lg__left" >
            <a className="site-logo"  href="/">
              <img className="site-logo-light"  src={logo} alt="MTN EnGauge logo" />
              {/*<img className="site-logo-dark" src={logoMobile} alt="MTN EnGauge logo" />*/}
              {/*<h2 className="sr-only">Ajua</h2>*/}
            </a>

          </div>

          <ul className="view-header-lg__nav">
            <li>
              <NavLink to="/">
                Company
              </NavLink>
            </li>
            {/*<li>*/}
            {/*  <a href="https://tappi.app/blog" target="_blank" rel="noopener noreferrer">*/}
            {/*    <span>Blog</span>*/}
            {/*    <i class="ri-arrow-right-up-line"></i>*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li>
              <NavLink to="/contact">
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
