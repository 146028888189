import React from "react";
import Layout from "../../components/layout";
import HeroImage from "../../common/images/marketing/ajua-consumers.jpg";
import LocalBusinessImage from "../../common/images/marketing/local-business.png";
import mtnLogo from "../../common/images/mtn-partnership.svg";
import engaugeLogo from "../../common/images/EnGauge_white.png";
import ReviewsImage from "../../common/images/marketing/reviews.png";
import WhatsAppImage from "../../common/images/marketing/whatsapp.png";
import BusinessFallback from "../../common/images/fallback/business-fallback.jpeg";

import Feet2Img from "../../common/images/businesses/Feet2Fit-Wellness.jpg";
import GTHairImg from "../../common/images/businesses/GT-Hair-2.jpg";
import OhenhelalocsImg from "../../common/images/businesses/OhenhelalocsImg.jpg";
import OlaNailszImg from "../../common/images/businesses/OlaNailszImg.jpg";

import HuntCoutureImg from "../../common/images/businesses/HuntCoutureImg.jpg";
import ReniStitchesImg from "../../common/images/businesses/ReniStitchesImg.jpg";
import Taqq24Img from "../../common/images/businesses/Taqq24Img.jpg";
import UrbanAdireImg from "../../common/images/businesses/UrbanAdireImg.jpg";

import ApellaCuisinesImg from "../../common/images/businesses/ApellaCuisinesImg.jpg";
import WingsvilleImg from "../../common/images/businesses/WingsvilleImg.jpg";
import NORMAImg from "../../common/images/businesses/NORMAImg.jpg";
import MicosChickenWafflesImg from "../../common/images/businesses/MicosChickenWafflesImg.jpg";

function ForConsumers() {
  document.body.classList.remove('business');
  return (
    <Layout>
      <div className="banner-cats">
        <div className="banner">
          <div className="banner__content">
            <div className="wrapper">
              <h1 className="h1">Discover trusted businesses&nbsp;in&nbsp;Nigeria</h1>
              <p className="h3 text-white mb-4">Search over 100,000 business profiles</p>

              <div className="consumer-search-wrap">

                <form className="lone-search">
                  <input
                    type="text"
                    placeholder="Search for your favorite business soon!"
                    value=""
                  />
                  <button className="lone-search__icon-wrap" type="submit">
                    <i className="ri-search-2-line"></i>
                  </button>
                </form>

                <img width="100" src={engaugeLogo} alt="EnGauge logo" />
                <img width="100" src={mtnLogo} alt="in partnership with MTN" />
              </div>

              <div className="slider slider--cats flex-justify-center">
                <a className="cat-pill" href="/">
                  Fashion
                </a>
                <a className="cat-pill" href="/">
                  Beauty
                </a>
                <a className="cat-pill" href="/">
                  Food & Beverage
                </a>
                <a className="cat-pill" href="/">
                  Home & Furniture
                </a>
                <a className="cat-pill" href="/">
                  Hospitality
                </a>
                <a className="cat-pill" href="/">
                  Vehicles
                </a>
                <a className="cat-pill" href="/">
                  Real Estate
                </a>
                <a className="cat-pill" href="/">
                  Appliances
                </a>
                <a className="cat-pill" href="/">
                  Electronics
                </a>
              </div>
            </div>
          </div>
          <figure className="banner__img">
            <img src={HeroImage} alt="man using Ajua on his phone" />
          </figure>

          <div className="banner__ft">
            <p>
              Are you a business owner?
              <a className="btn-outline mt-3 mt-md-0 ml-sm-3" href="/contact">
                Join Our Community <i className="ri-arrow-right-line"></i>
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="wrapper mt-6 pt-6 mb-6 mb-lg-0 pb-4 pb-lg-0">
        <h3 className="h6 mb-4">Top Rated Verified Beauty</h3>

        <div className="slider slider--business">
          <a class="business-preview" href="/business/ng/87781">
            <figure className="business-preview__featured-image">
              <img src={Feet2Img} alt="Feet2 Fit Wellness" />
            </figure>

            <div className="business-preview__content">
              <h4>Feet2 Fit Wellness</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>7 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <a class="business-preview" href="/business/ng/101636">
            <figure className="business-preview__featured-image">
              <img src={GTHairImg} alt="GT Hair" />
            </figure>

            <div className="business-preview__content">
              <h4>GT Hair</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>5 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <a class="business-preview" href="/business/ng/74888">
            <figure className="business-preview__featured-image">
              <img src={OhenhelalocsImg} alt="Ohenhelalocs" />
            </figure>

            <div className="business-preview__content">
              <h4>Ohenhelalocs</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>5 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <a class="business-preview" href="/business/ng/68512">
            <figure className="business-preview__featured-image">
              <img src={OlaNailszImg} alt="Ola Nailsz" />
            </figure>

            <div className="business-preview__content">
              <h4>Ola Nailsz</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>12 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <span className="spacer"></span>
          <span className="spacer"></span>
        </div>
      </div>


      <div className="wrapper mb-6 mb-lg-0 pb-4 pb-lg-0">
        <h3 className="h6 mb-4">Top Rated Verified Fashion</h3>

        <div className="slider slider--business">
          <a class="business-preview" href="/business/ng/106553">
            <figure className="business-preview__featured-image">
              <img src={HuntCoutureImg} alt="Hunt Couture" />
            </figure>

            <div className="business-preview__content">
              <h4>Hunt Couture</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>3 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>

            </div>
          </a>

          <a class="business-preview" href="/business/ng/71016">
            <figure className="business-preview__featured-image">
              <img src={ReniStitchesImg} alt="Reni Stitches" />
            </figure>

            <div className="business-preview__content">
              <h4>Reni Stitches</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>9 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <a class="business-preview" href="/business/ng/68667">
            <figure className="business-preview__featured-image">
              <img src={Taqq24Img} alt="Taqq24" />
            </figure>

            <div className="business-preview__content">
              <h4>Taqq24</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>10 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <a class="business-preview" href="/business/ng/69182">
            <figure className="business-preview__featured-image">
              <img src={UrbanAdireImg} alt="Urban Adire" />
            </figure>

            <div className="business-preview__content">
              <h4>Urban Adire</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>7 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <span className="spacer"></span>
          <span className="spacer"></span>
        </div>
      </div>

      <div className="wrapper mb-6 mb-lg-0 pb-4 pb-lg-0">
        <h3 className="h6 mb-4">Top Rated Verified Food</h3>

        <div className="slider slider--business">
          <a class="business-preview" href="/business/ng/102579">
            <figure className="business-preview__featured-image">
              <img src={ApellaCuisinesImg} alt="Apella Cuisines" />
            </figure>

            <div className="business-preview__content">
              <h4>Apella Cuisines</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>2 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <a class="business-preview" href="/business/ng/73587">
            <figure className="business-preview__featured-image">
              <img src={WingsvilleImg} alt="Wingsville" />
            </figure>

            <div className="business-preview__content">
              <h4>Wingsville</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>3 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <a class="business-preview" href="/business/ng/68993">
            <figure className="business-preview__featured-image">
              <img src={NORMAImg} alt="NORMA" />
            </figure>

            <div className="business-preview__content">
              <h4>NORMA</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>7 reviews</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <a class="business-preview" href="/business/ng/107996">
            <figure className="business-preview__featured-image">
              <img src={MicosChickenWafflesImg} alt="Micos Chicken Waffles" />
            </figure>

            <div className="business-preview__content">
              <h4>Micos Chicken Waffles</h4>

              <rating-preview>
                <i className="ri-star-fill"></i>
                <span>5</span>
                <span>1 review</span>
              </rating-preview>

              <address className="d-flex flex-items-center">
                <i class="ri-map-pin-2-fill text-light mr-1"></i>
                Lagos
              </address>
            </div>
          </a>

          <span className="spacer"></span>
          <span className="spacer"></span>
        </div>
      </div>

      <div className="wrapper py-6 mb-lg-6 ajua-bubbles-wrap">
        <svg className="ajua-bubbles" width="80" height="103" viewBox="0 0 80 103">
          <circle cx="15.0066" cy="87.9934" r="15.0066" fill="#FFC550"/>
          <circle cx="24.5562" cy="57.2979" r="12.2781" fill="#FFC550"/>
          <circle cx="30.0132" cy="79.1258" r="15.0066" fill="#002366"/>
          <circle cx="38.8807" cy="37.5166" r="19.0993" fill="#002366"/>
          <circle cx="53.2053" cy="56.6158" r="12.2781" fill="#FFC550"/>
          <circle cx="60.7086" cy="19.0993" r="19.0993" fill="#4B92DB"/>
        </svg>

        <div className="col-md-10 col-lg-5">
          <h3 className="h2">About Ajua</h3>
          <p>
            Ajua is Africa’s largest network of trusted local businesses. We
            believe in the power of community and connection.
          </p>
        </div>

        <div className="d-flex mt-4">
          <div className="col-4 pr-2">
            <figure className="img-square mb-2 mb-lg-4">
              <img src={LocalBusinessImage} alt="Find local businesses" />
              <p className="font-weight-500">Find local businesses</p>
            </figure>
          </div>
          <div className="col-4 px-1">
            <figure className="img-square mb-2 mb-lg-4">
              <img src={ReviewsImage} alt="Read and leave reviews" />
              <p className="font-weight-500">Read and leave reviews</p>
            </figure>

          </div>
          <div className="col-4 pl-2">
            <figure className="img-square mb-2 mb-lg-4">
              <img src={WhatsAppImage} alt="Engage on WhatsApp" />
              <p className="font-weight-500">Engage on WhatsApp</p>
            </figure>
          </div>
        </div>
      </div>

      <hr />
    </Layout>
  );
}

export default ForConsumers;
