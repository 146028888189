import React from "react";
import Layout from "../../components/layout";
import getStarted from "../../common/images/marketing/get-started.jpeg";

function Contact() {
  document.body.classList.remove('business');
  return (
    <Layout>
      <div className="market-hero">
        <div className="wrapper">
          <div className="market-hero__content">
            <h1 className="h6 text-white">Get in touch</h1>
            <p className="h1 mb-3">Contact</p>
          </div>
        </div>
      </div>

      <div className="wrapper py-7">
        <div class="d-md-flex flex-justify-center">
          <div class="col-12 col-md-4 mb-6 pr-md-4">
          <h2 className="h5">Nairobi</h2>
          <p>
          Call: <a href="tel:+254719113770">+254 719 113 770</a><br />
          Email: <a href="mailto:info@ajua.com">info@ajua.com</a><br />
          Woodlands Trail, Woodlands Rd, Kilimani, Nairobi
          </p>
          </div>
          <div class="col-12 col-md-4 mb-6 px-md-3">
            <h2 className="h5">Lagos</h2>
            <p>
              WhatsApp: <a href="tel:+2349060000070">+2349136000775</a><br />
              Call: <a href="tel:+2349060000070">+234 9136 000 775</a><br />
              Email: <a href="mailto:mtn-support@ajua.com">mtn-support@ajua.com</a><br />
              Plot 8 The Providence St, Lekki Phase I 106104, Lekki, Nigeria
            </p>
          </div>
          <div class="col-12 col-md-4 pl-md-4">
            <h2 className="h5">New York</h2>
            <p>
              1133 Broadway Suite 304<br />
              New York, NY 10010<br />
              Call: <a href="tel:+855-682-9626">855-682-9626</a>
            </p>
          </div>
        </div>
      </div>

      <div className="wrapper py-7 d-none">
        <form className="main-contact" validate>
          <div className="form-field">
            <label for="name">Name*</label>
            <input id="name" type="text" required />
          </div>
          <div className="form-field">
            <label for="email">Email*</label>
            <input id="email" type="email" required />
          </div>
          <div className="form-field">
            <label for="phone">Phone number</label>
            <input id="phone" type="tel" />
          </div>

          <div className="form-field">
            <label for="message">Message*</label>
            <textarea id="message" required></textarea>
          </div>

          <div className="form-field">
            <button class="btn" type="submit">Submit</button>
          </div>
        </form>
      </div>

      <hr />

      <div className="wrapper py-7">
        <div className="market-hero market-hero--auto">
          <div className="wrapper">
            <div className="market-hero__content">
              <h2 className="h2 mb-3">Get started with Thryve by downloading our mobile app today</h2>
              <a className="btn-outline btn--no-hover mt-2 mr-2"
                 href="https://play.google.com/store/apps/details?id=com.mtn.gauge&hl=en_US&gl=US"
                 target="_blank" rel="noopener">
                <i className="ri-android-fill" style={{fontSize: "16px"}}></i>
                <span>Get the app on  Android</span>
              </a>
              <a className="btn-outline btn--no-hover mt-2"
                 href="https://apps.apple.com/us/app/mtn-thryve/id6447981321"
                 target="_blank" rel="noopener">
                <i className="ri-apple-fill" style={{fontSize: "16px"}}></i>
                <span>Get the app on  iOS</span>
              </a>
            </div>
          </div>
          <figure className="market-hero__img">
            <img src={getStarted} alt="Get started with MTN Enguage by downloading our mobile app today"/>
          </figure>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
