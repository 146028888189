import React from "react";
import Layout from "../../components/layout";
import ajuaBusinessGraphic from "../../common/images/marketing/ajua-hero-business.jpg";
//import customers from "../../common/images/marketing/ajua-team.jpg";
import customers from "../../common/images/people.png"
//import ads from "../../common/images/marketing/ads.png";
import ads from "../../common/images/ads.png";
//import improve from "../../common/images/marketing/improve.png";
import improve from "../../common/images/webpage.png";
//import reach from "../../common/images/marketing/reach.png"
import reach from "../../common/images/offers.png";
import getStarted from "../../common/images/marketing/get-started.jpeg";
import mtnLogo from "../../common/images/mtn-partnership.svg"
import tappiLogo from "../../common/images/tappi-header-logo.png"

function ForBusiness() {
    document.body.classList.remove('business');
    return (
        <Layout>
            <div className="market-hero market-hero--stats">
                <div className="wrapper">
                    <div className="market-hero__content">
                        <p className="h1 mb-3">Find more customers.<br/> Bring your business online.</p>
                        {/*<p className="p-lg">*/}
                        {/*    Startup your first business webpage in minutes. Easily power online transactions, Buy Ads*/}
                        {/*    with your Airtime on Facebook, Instagram, and Google. Get ratings and reviews to build your*/}
                        {/*    business credibility, and enlarge your customer base.*/}
                        {/*</p>*/}
                        {/*<p className="p-lg">*/}
                        {/*    See why your business credibility is important to getting more customers.*/}
                        {/*</p>*/}
                        <div className="d-md-flex flex-items-center mt-4">
                            <a className="btn-outline btn--no-hover mt-4 mr-4"
                               href="https://play.google.com/store/apps/details?id=com.mtn.thryve&hl=en&gl=US"
                               target="_blank" rel="noopener">
                                <i className="ri-android-fill" style={{fontSize: "16px"}}></i>
                                <span>Get the app on Android</span>
                            </a>
                            <a className="btn-outline btn--no-hover mt-4 mr-4"
                               href="https://apps.apple.com/us/app/mtn-thryve/id6447981321"
                               target="_blank" rel="noopener">
                                <i className="ri-apple-fill" style={{fontSize: "16px"}}></i>
                                <span>Get the app on  iOS</span>
                            </a>
                        </div>
                        <div className="d-flex flex-items-center mt-5 flex-justify-center-sm">
                            <p className=" mr-3">Powered by</p>
                            <img width="100" className="mr-3" src={tappiLogo} alt="EnGauge logo"/>
                            <img width="100" src={mtnLogo} alt="in partnership with MTN"/>
                        </div>
                    </div>
                </div>
                <figure className="market-hero__img">
                    <img src={ajuaBusinessGraphic} alt="Tappi helps your business"/>
                </figure>
                <div className="market-hero__stats">
                    {/*<div>*/}
                    {/*    <p>*/}
                    {/*        <b>$500M</b>*/}
                    {/*        recorded purchases*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <p>*/}
                    {/*        <b>100,000+</b>*/}
                    {/*        businessses*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <p>*/}
                    {/*        <b>35,000+</b>*/}
                    {/*        monthly reviews*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <p>*/}
                    {/*        <b>22M+</b>*/}
                    {/*        consumers reached*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                </div>
            </div>

            {/*<div className="wrapper py-7">*/}
            {/*    <div className="media-object media-object--sm-media media-object--flip">*/}
            {/*        <figure className="media-object__media">*/}
            {/*            <img src={customers} alt="The Tappi team"/>*/}
            {/*        </figure>*/}
            {/*        <div className="media-object__content">*/}
            {/*            <div>*/}
            {/*                <h3 className="h2">Our mission</h3>*/}
            {/*                <p className="p-lg"><b>Our mission at MTN Thryve is to bridge the gap between businesses and*/}
            {/*                    customers by understanding their needs and connecting them effectively.</b>. As*/}
            {/*                    matchmakers, we leverage the vast network of MTN Thryve's businesses and the Audience*/}
            {/*                    network of consumers across the continent.</p>*/}
            {/*                /!*<p className="p-lg">Our ultimate goal is to drive more transactions within a growing payment*!/*/}
            {/*                /!*    ecosystem.</p>*!/*/}

            {/*                <h3 className="h2 mt-6">Our vision</h3>*/}
            {/*                <p className="p-lg">Make every small business a successful business</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="wrapper">*/}
            {/*    <div className="testimonial">*/}
            {/*        <div>*/}
            {/*            <q>Bring your business into the <span>pocket of your customers</span>.</q>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="wrapper py-7">
                <div className="text-center mb-6">
                    <h2 className="h2">Why MTN Thryve?</h2>
                </div>

                <div className="media-object ">
                    <figure className="media-object__media">
                        <img width="340px" src={ads} alt="Simplified Facebook & Instagram Ads"/>
                    </figure>
                    <div className="media-object__content">
                        <div>
                            <h3 className="h3"><span className="text-brand">Simplified</span> Facebook & Instagram Ads
                            </h3>
                            <p>
                                We know how painful it is to get your brand and business online with Facebook and
                                Instagram. We’ve tried it! So we’ve simplified it for you to just 3 clicks!
                            </p>
                            <p>
                                <b>No credit card?</b> - That’s OK; use your MTN Airtime! Our customers love this
                                option. Over 85% of our customers select this option.
                            </p>
                            <p>
                                <b>No Facebook or Instagram account?</b> - That’s OK; your ad will reach over 10,000
                                potential customers @ only N1000 daily.
                            </p>
                            <p>YOU WILL NEVER BE ASKED TO SAVE YOUR CREDIT CARD INFORMATION</p>
                        </div>
                    </div>
                </div>

                <div className="media-object media-object--flip">
                    <figure className="media-object__media">
                        <img width="340px" src={improve}
                             alt="Improve your online presence with a free business page on EnGauge.site"/>
                    </figure>
                    <div className="media-object__content">
                        <div>
                            <h3 className="h3"><span className="text-brand">Improve</span> your online presence with a
                                free website for your business</h3>
                            <p>
                                Join over 100,000 businesses and make your brand visible with a free webpage upon
                                signing up.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="media-object ">
                    <figure className="media-object__media">
                        <img width="340px" src={reach} alt="Reach your customers"/>
                    </figure>
                    <div className="media-object__content">
                        <div>
                            <h3 className="h3"><span className="text-brand">Reach</span> your customers with our 1-click
                                Bulk SMS tool</h3>
                            <p>
                                Have you ever wanted to send your customers a holiday greeting message, discount, or
                                offer? Now you can.
                            </p>
                            <p>
                                We are bringing all the tools for you to operate like a professional and big business.
                            </p>
                            <p>
                                Send birthday, holiday, and discount messages to keep your customers engaged and coming
                                back!
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="wrapper py-7">
                <div className="market-hero market-hero--auto">
                    <div className="wrapper">
                        <div className="market-hero__content">
                            <h2 className="h2 mb-3">Get started with Thryve by downloading our mobile app today</h2>
                            <a className="btn-outline btn--no-hover mt-2 mr-2"
                               href="https://play.google.com/store/apps/details?id=com.mtn.gauge&hl=en_US&gl=US"
                               target="_blank" rel="noopener">
                                <i className="ri-android-fill" style={{fontSize: "16px"}}></i>
                                <span>Get the app on  Android</span>
                            </a>
                            <a className="btn-outline btn--no-hover mt-2"
                               href="https://apps.apple.com/us/app/mtn-thryve/id6447981321"
                               target="_blank" rel="noopener">
                                <i className="ri-apple-fill" style={{fontSize: "16px"}}></i>
                                <span>Get the app on  iOS</span>
                            </a>
                        </div>
                    </div>
                    <figure className="market-hero__img">
                        <img src={getStarted} alt="Get started with MTN Enguage by downloading our mobile app today"/>
                    </figure>
                </div>
            </div>


            {/*<div className="py-6 pb-md-6 wrapper">*/}
            {/*    <h3 className="h6">Our Partners</h3>*/}

            {/*    <Partners></Partners>*/}

            {/*</div>*/}

            <hr/>
        </Layout>
    );
}

export default ForBusiness;
