import React from "react";
import { useParams } from "react-router-dom";
import FooterIcon from "../../common/images/tappi-footer-logo.png";
import AjuaIcon from "../../common/images/thryve_logo_white.svg";


function Footer() {

  const { countryCode } = useParams();

  return (
    <>
      {/*<div className="wrapper-full py-7">*/}
      {/*  <div className="wrapper d-md-flex">*/}
      {/*    <div className="col-12 col-md-4 mb-6 mb-md-0 pr-md-4">*/}
      {/*      <div>*/}
      {/*        <h4>Use Thryve at your business</h4>*/}
      {/*        <p>Everything you need to build and grow your business.</p>*/}
      {/*        <a className="icon-link h6 mt-4" href="/contact">*/}
      {/*          <span>Learn more</span>*/}
      {/*          <i class="ri-arrow-right-s-line"></i>*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="col-12 col-md-4 mb-6 mb-md-0 pr-md-4">*/}
      {/*      <div>*/}
      {/*        <h4>Download the Thryve merchant app</h4>*/}
      {/*        <p>Manage your business from anywhere at your fingertips.</p>*/}
      {/*        <a className="icon-link h6 mt-4" href="https://play.google.com/store/apps/details?id=com.mtn.gauge&hl=en_US&gl=US" target="_blank">*/}
      {/*          <span>Download it now</span>*/}
      {/*          <i class="ri-arrow-right-s-line"></i>*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="col-12 col-md-4 pr-md-4">*/}
      {/*      <div>*/}
      {/*        <h4>Request a new business</h4>*/}
      {/*        <p>Have a favorite business but don’t see it? Let us know.</p>*/}
      {/*        <a className="icon-link h6 mt-4" href="/contact">*/}
      {/*          <span>Suggest a business</span>*/}
      {/*          <i class="ri-arrow-right-s-line"></i>*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <footer className="site-footer">
        <div className="wrapper">

          <div class="d-md-flex flex-justify-between flex-items-center pb-4">
            {countryCode !== 'ng' ? null : <a className="site-footer-icon" href="/">
              <img width="160" src={FooterIcon} alt="Tappi Icon" />
            </a>}

            <ul className="social-list">
              <li>
                <a href="https://www.facebook.com/profile.php?id=100089128442632" target="_blank" rel="noreferrer">
                  <i className="ri-facebook-circle-fill"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/tappi_app/" target="_blank" rel="noreferrer">
                  <i className="ri-instagram-fill"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/86786367" target="_blank" rel="noreferrer">
                  <i className="ri-linkedin-fill"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/get_tappi" target="_blank" rel="noreferrer">
                  <i className="ri-twitter-fill"></i>
                </a>
              </li>
            </ul>
          </div>

          <div class="d-md-flex flex-justify-between flex-items-center pt-4">
            <div className="site-footer__ajua">
              <img src={AjuaIcon} alt="Ajua Icon" width={140}/>
            </div>
            <div>
              <ul className="legal-list">
                <li>

                </li>
                <li>
                  <a href="/privacy-policy/">
                    Privacy Policy
                  </a>
                </li>
              </ul>

              <small class="h6 mt-3">© 2023 Tappi, Inc. All Rights Reserved</small>
            </div>

          </div>

        </div>
      </footer>
    </>
  );
}

export default Footer;
