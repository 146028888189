import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ScrollToTop from "./ScrollToTop";
import ForConsumers from "./pages/for-consumers";
import Business from "./pages/business";
import ForBusiness from "./pages/for-business";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacy";
import Search from "./pages/search";
import Review from "./pages/review";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
      <Route
          path="/:businessId"
          element={<Business />}
        />
        <Route
          path="business/:countryCode/:businessId"
          element={<Business />}
        />
        <Route path="for-consumers" element={<ForConsumers />} />
        <Route path="contact" element={<Contact />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="add-review/:countryCode/:businessId"
          element={<Review />}
        />
        // <Route path="search" element={<Search />} />
        <Route index element={<ForBusiness />} />
      </Routes>
    </AnimatePresence>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
