import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import axios from "axios";
import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
import BusinessFallback from "../../common/images/fallback/business-fallback.jpeg";
import UserFallback from "../../common/images/fallback/user-fallback.png";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

function Business() {
  const { businessId, countryCode } = useParams();
  const [merchant, setMerchant] = useState([]);
  const [merchantProductPhotos, setMerchantProductPhotos] = useState([]);
  // const [showAllPhotos, setShowAllPhotos] = useState(false);
  // const [limitPhotos, setLimitPhotos] = useState(7)
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [limitReviews, setLimitReviews] = useState(8)
  const hostname = window.location.hostname;
  document.body.classList.add("business");

  //Get Hostname
  const fetchCountryCode = (hostname) =>{
    if(hostname === 'ajua.com' || hostname === 'localhost'){
      return countryCode
    } else if(hostname === 'engauge.ng'){
      return 'ng'
    } else{
      return 'ke';
    }
  }

  const limitReviewsHandler = () => {
    if(showAllReviews === true){
      setShowAllReviews(false);
      setLimitReviews(6)
    } else {
      setShowAllReviews(true)
      setLimitReviews(merchant[1]?.reviews_count)
    }
  }

  // const limitPhotosHandler = () => {
  //   if(showAllPhotos === true){
  //     setShowAllPhotos(false);
  //     setLimitPhotos(7)
  //   } else {
  //     setShowAllPhotos(true)
  //     setLimitPhotos(merchantProductPhotos[0].products.length)
  //   }
  // }


  useEffect(() => {
      axios
      .get(
        `https://ajua-com-api.ajua.com/engauge/merchant/api/v1/merchants/${fetchCountryCode(hostname)}/${businessId}`
      )
      .then((res) => {
        setMerchant(res?.data);
      })
      .catch((err) => {
        console.log(err);
        
      });

    if (fetchCountryCode(hostname) === "ng") {
      axios
        .get(
          `https://merchant-api.ajua.com/v0/get_products_web?merchant_code=${businessId}`,
          {
            headers: {
              Authentication: "52516d27-26b8-430f-b729-5c15fb999382",
            },
          }
        )
        .then((res) => {
          setMerchantProductPhotos(res?.data);
          
        })
        .catch((err) => {
          console.log(err);
          
        });
    } else {
      axios
        .get(
          `https://ajua-com-api.ajua.com/engauge/merchant/api/v1/tathmini-photos/${businessId}`
        )
        .then((res) => {
          setMerchantProductPhotos(res?.data);
          
        })
        .catch((err) => {
          console.log(err);
          
        });
    }
  }, [businessId]);


  const dateFormat = (date) => {
    const dateData = new Date(date);

    const monthOfTheYear = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = dateData.getDate();
    const month = dateData.getMonth();
    const year = dateData.getFullYear();

    return monthOfTheYear[month] + " " + day + ", " + year;
  };

  const fetchGoogleAvatar = (email) => {
    if (email) {
      const harshEmail = CryptoJS.MD5(email);
      return harshEmail.toString();
    }
    return;
  };

  function openShare(e) {
    e.preventDefault();
    document.getElementById('social-share').classList.toggle("is-open");
  }



  return (    
    <Layout>
      <business>
        <header class="business__header">
          <figure class="business__featured-image">
            <img
              src={
                merchant[0]?.cover_photo
                  ? merchant[0].cover_photo.includes('https') ? merchant[0].cover_photo :`data:image/png;base64,${merchant[0]?.cover_photo}`
                  : BusinessFallback
              }
            />
          </figure>

          <div class="business__header-content wrapper">
            <figure class="business__logo">
              <img
                src={
                  merchant[0]?.profile_photo
                    ? merchant[0].profile_photo.includes('https') ? merchant[0].profile_photo :`data:image/png;base64,${merchant[0]?.profile_photo}`
                    : UserFallback
                }
              />
            </figure>
            <h3>{merchant[0]?.business_name}</h3>

            <div class="business__header__details">
              <rating-preview>
                <i class="ri-star-fill"></i>
                <span>{merchant[1]?.average_rating || 0}</span>
                <a href="#business__reviews">{`${
                  merchant[1]?.reviews_count || 0
                } reviews`}</a>
              </rating-preview>

              <business-category>
                <span>
                  {merchant[0]?.industry ? `${merchant[0]?.industry}` : ""}
                </span>
              </business-category>
              {
                <address>
                  {merchant[0]?.state
                    ? `${merchant[0]?.state || ""}, ${
                      fetchCountryCode(hostname) === "ng" ? "Nigeria" : "Kenya" || ""
                      }`
                    : merchant[0]?.ajua_account_details?.country || ""}
                </address>
              }
            </div>

            <div class="business-header__social d-flex flex-items-center mt-3">
             {merchant[0]?.msisdn && <a
                class="icon-circle mr-4 mr-lg-0 ml-lg-4"
                href={
                  merchant[0]?.msisdn
                    ? `https://wa.me/${merchant[0]?.msisdn}`
                    : ""
                }
              >
                <i class="ri-whatsapp-fill"></i>
              </a>}
              <a
                className="icon-circle mr-4 mr-lg-0 ml-lg-4"
                href={`/add-review/${fetchCountryCode(hostname)}/${businessId}?name=${
                  merchant[0]?.business_name || ""
                }`}
              >
                <i className="ri-pencil-fill"></i>
              </a>
              <div className="social-share-wrap mr-4 mr-lg-0 ml-lg-4" id="social-share">
                <a className="icon-circle" href="" onClick={openShare}>
                  <i className="ri-share-fill"></i>
                </a>

                <div class="social-share-list">
                  <FacebookShareButton
                    url={fetchCountryCode(hostname) === 'ng' ? `https://engauge.ng/${businessId}`: window.location.href}
                    title={`Check out ${merchant[0]?.business_name} on ${fetchCountryCode(hostname) === 'ng' ? '@engaugeapp' : '@ajuaafrica'}`}
                  >
                    Facebook
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={fetchCountryCode(hostname) === 'ng' ? `https://engauge.ng/${businessId}`: window.location.href}
                    title={`Check out ${merchant[0]?.business_name} on ${fetchCountryCode(hostname) === 'ng' ? '@MTN_EnGauge' : '@Ajua_Africa'}`}
                  >
                    Twitter
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={fetchCountryCode(hostname) === 'ng' ? 'engauge.ng' : 'ajua.com'}
                    title={`Check out ${merchant[0]?.business_name} ${fetchCountryCode(hostname) === 'ng' ? `https://engauge.ng/${businessId}`: window.location.href} on`}
                  >
                    WhatsApp
                  </WhatsappShareButton>
                  <EmailShareButton
                    subject={`Check out ${merchant[0]?.business_name} on ${fetchCountryCode(hostname) === 'ng' ? 'engauge.ng' : 'ajua.com'}`}
                    body={fetchCountryCode(hostname) === 'ng' ? `https://engauge.ng/${businessId}`: window.location.href}
                  >
                    Email
                  </EmailShareButton>
                </div>
              </div>

            </div>
          </div>
        </header>

        <div class="business__about wrapper">
          <h3 class="h6">About</h3>
          <p>{merchant[0]?.business_description}</p>
        </div>

        {merchantProductPhotos[0]?.status && (
          <div class="business__photos wrapper">
            <h3 class="h6">Photos</h3>
            <ul class="business__photo-gallery">
            {merchantProductPhotos[0]?.products?.map((product, index, products) => {
                  return(
                  <li key={index}>
                    <img src={product.image.includes('https') ? product.image :`data:image/png;base64,${product?.image}`} />
                  </li>
                  )
              })}
              {/* {merchantProductPhotos[0]?.products?.map((product, index, products) => {
                if(showAllPhotos === true){
                  return(
                  <li key={index}>
                    <img src={product.image.includes('https') ? product.image :`data:image/png;base64,${product?.image}`} />
                  </li>
                  )
                } else {
                  return (
                     products.length > 7 && <li key={index} style={index === 7 ?{opacity:'0.2', position: 'relative', textAlign:'center'}: {}}>
                      <img src={product.image.includes('https') ? product.image :`data:image/png;base64,${product?.image}`} />
                      {index === 7 ? <p style={{position:'absolute', top: '50%', left: '50%', fontWeight:'bolder', fontSize: '2rem', color:'black', zIndex:'4'}} onClick={limitPhotosHandler}>+{products.length}</p>:''}
                    </li>
                  );
                }
              })} */}
            </ul>
          </div>
        )}

        <div class="business__reviews wrapper" id="business__reviews">
          <rating-preview>
            <i class="ri-star-fill"></i>
            <span>{merchant[1]?.average_rating || 0}</span>
            <span>{`${merchant[1]?.reviews_count || 0} reviews`}</span>
          </rating-preview>
          <reviews>
            {merchant[1]?.business_reviews?.map((review) => {
              return (
                <review>
                  <review-header>
                    <review-customer>
                      <figure>
                        <img
                          src={`https://www.gravatar.com/avatar/${fetchGoogleAvatar(
                            review.email_address
                          )}?d=mp`}
                          width="80"
                          height="80"
                        />
                      </figure>
                      <div>
                        <p class="review-customer-name">{review?.name}</p>
                        <p>{dateFormat(review?.created_at)}</p>
                      </div>
                    </review-customer>
                    <review-rating>
                      {[...Array(+review?.rating_value).keys()].map(() => (
                        <i class="ri-star-fill"></i>
                      ))}
                      {[...Array(5 - +review?.rating_value)].map(() => (
                        <i class="ri-star-line"></i>
                      ))}
                    </review-rating>
                  </review-header>
                  <review-review>
                    <p>{review?.review}</p>
                  </review-review>
                </review>
              );
            }).reverse().slice(0, limitReviews)}
          </reviews>

          <p data-modal="reviews-modal" style={{color: 'black', cursor: 'pointer', marginTop: 20}} onClick={limitReviewsHandler}>
            { merchant[1]?.business_reviews.length > 6 ? showAllReviews?'Show less reviews':'Show all reviews' : ''}
          </p>
        </div>
      </business>
    </Layout>
  );
}

export default Business;