import axios from "axios";
import React, { useState } from "react";
import Layout from "../../components/layout";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { TailSpin } from  'react-loader-spinner';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function Review() {
  document.body.classList.add("business");
  const { businessId, countryCode } = useParams();
  const hostname = window.location.hostname;
  let { search } = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(search);
  const merchantName = query.get("name");

  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState({
    name: "",
    email_address: "",
    rating_value: "",
    review: "",
  });
  const [validEmail, setValidemail] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const [emptyField, setEmptyField] = useState({
    name: "",
    email_address: "",
    review: "",
    rating_value: "",
  });

  const handleChange = (key, value) => {
    if (!value) {
      setEmptyField({ ...emptyField, [key]: "This Field is Required!" });
    } else {
      setEmptyField({ ...emptyField, [key]: "" });
    }

    if (key === "email_address") {
      const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      setValidemail(emailValid ? "" : "Please enter valid email!");
    }
    setValues({ ...values, [key]: value });
  };

  const emailHandleChange = (key, value) => {

    if (value || key === "email_address") {
      const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      setValidemail(emailValid ? "" : "Please enter valid email!");
    }
    setValues({ ...values, [key]: value });
  };

  const handleMouseOver = (e) => {
    const target = e.target;
    target.classList.add("is-hovering");
    document.getElementById("review-rating").classList.add("is-hovering");
    let el = target;
    while (el) {
      el = el.previousElementSibling;
      if (el) {
        el.classList.add("is-hovering");
      }
    }
  };

  const handleMouseOut = (e) => {
    document.getElementById("review-rating").classList.remove("is-hovering");
    var elems = document.querySelectorAll(".is-hovering");
    [].forEach.call(elems, function (el) {
      el.classList.remove("is-hovering");
    });
  };

  const handleValidation = () => {
    let isvalid = true;
    let emptyField = {};
    if (!values.name) {
      isvalid = false;
      emptyField["name"] = "This Field is Required!";
    }
    if (!values.review) {
      isvalid = false;
      emptyField["review"] = "This Field is Required!";
    }

    if (!values?.rating_value) {
      isvalid = false;
      emptyField["rating_value"] = "This Field is Required!";
    }

    setEmptyField({
      ...emptyField,
      name: emptyField.name,
      email_address: emptyField.email_address,
      review: emptyField.review,
      rating_value: emptyField.rating_value,
    });
    return isvalid;
  };

  const handleSubmit = (e) => {
    if (handleValidation() && !validEmail) {
      setIsLoading(true);
      axios
        .post(
          `https://ajua-com-api.ajua.com/engauge/merchant/api/v1/add-review/${countryCode}/${businessId}`,
          JSON.stringify({ ...values, merchant_id: businessId }),
          { headers: { "Content-Type": "application/json" } }
        )
        // .then(res => {
        //   axios
        //     .post("http://localhost:3001/post-review",JSON.stringify({review: { ...values, merchant_id: businessId, createDate: new Date() }}),
        //     { headers: { "Content-Type": "application/json" } })
        // })
        .then((res) => {
          setIsLoading(false);
          alert("Review is saved successfully, thank you!");
          setValues({
            name: "",
            email_address: "",
            rating_value: "",
            review: "",
          });
          navigate(hostname === 'ajua.com' ? `/business/${countryCode}/${businessId}`:`/${businessId}`);
        })
        .catch((err) => {
          setIsLoading(false);
          alert(`Error: ${err}`);
        });
    }

    e.preventDefault();
  };

  const onChange = (value) => {
    // console.log("Captcha value:", value);
    setIsVerified(true);
  };

  return (
    <Layout>
      <div className="wrapper">
        <div className="review-form">
          <div className="mb-6">
            <h2 className="h6">Write a Review</h2>
            <p className="h3">{merchantName || "-"}</p>
            <hr />
          </div>

          <form onSubmit={() => handleSubmit()}>
            <div className="form-field">
              <label className="mb-3">How would you rate this business?</label>
              <div
                className={`input-star-list ${
                  values.rating_value
                    ? `input-star-list--${values.rating_value}`
                    : ``
                }`}
                id="review-rating"
              >
                {[1, 2, 3, 4, 5].map((value, index) => (
                  <div
                    key={index}
                    className="input-star"
                    data-rating="1"
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={(e) => handleChange("rating_value", value)}
                  >
                    <i className="ri-star-line"></i>
                    <i className="ri-star-fill"></i>
                  </div>
                ))}
              </div>

              {emptyField?.rating_value?.length > 0 && (
                <p style={{ fontSize: "14px", color: "red" }}>
                  {emptyField?.rating_value || "-"}
                </p>
              )}
            </div>

            <div className="d-md-flex">
              <div className="col-12 col-md-6 pr-md-3">
                <div className="form-field">
                  <label>Name</label>
                  <input
                    type="text"
                    value={values.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    required
                  />
                  {emptyField?.name?.length > 0 && (
                    <p style={{ fontSize: "14px", color: "red" }}>
                      {emptyField?.name || "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 pl-md-3">
                <div className="form-field">
                  <label>Email</label>
                  <input
                    type="email"
                    value={values.email_address}
                    onChange={(e) =>
                      emailHandleChange("email_address", e.target.value)
                    }
                  />
                  {values.email_address.length > 0 && validEmail && (
                    <p style={{ fontSize: "14px", color: "red" }}>
                      {validEmail || "-"}
                    </p>
                  )}
                  {emptyField?.email_address?.length > 0 && (
                    <p style={{ fontSize: "14px", color: "red" }}>
                      {emptyField?.email_address || "-"}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="form-field">
              <label>Comments</label>
              <textarea
                value={values.review}
                placeholder="Provide as much detail as possible about this business to help others understand your experience..."
                onChange={(e) => handleChange("review", e.target.value)}
                required
              ></textarea>
              {emptyField?.review?.length > 0 && (
                <p style={{ fontSize: "14px", color: "red" }}>
                  {emptyField?.review || "-"}
                </p>
              )}
            </div>

            <ReCAPTCHA
              sitekey="6LcKQ5UgAAAAABX77IX0nRH4xMSGIgiA_vtK5VXE"
              onChange={onChange}
              style={{ marginBottom: "30px" }}
            />

            {isLoading ? 
            <TailSpin
              height="40"
              width="40"
              color='#ffcb03'
              ariaLabel='loading'
            />
            :
            <button
              className="btn"
              onClick={handleSubmit}
              disabled={!isVerified}
            >
              Submit Review
            </button>}
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default Review;